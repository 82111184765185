<template>
    <div class="d-grid gap-3 d-grid-template-1fr-19">
      <div class="card rounded  mb-0">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/51.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Jan</span>
                  <h5>01</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">New Year Celibration</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card rounded  mb-0">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/52.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Jan</span>
                  <h5>24</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Birthday Celibration</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card mb-0 rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/53.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Jan</span>
                  <h5>26</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Republic Day</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card mb-0 rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/54.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Feb</span>
                  <h5>04</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Meetings & Conventions</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card mb-0 rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/55.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>March</span>
                  <h5>01</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Fun Events and Festivals </router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card mb-0 rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/56.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>March</span>
                  <h5>10</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Atlanta Retail Show</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/57.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>March</span>
                  <h5>14</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Holi in the City</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/58.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Mar</span>
                  <h5>16</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">Insurance Innovators</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card rounded ">
          <div class="event-images">
            <a href="#">
            <img src="@/assets/images/page-img/59.jpg" class="img-fluid" alt="Responsive image">
            </a>
          </div>
          <div class="card-body">
            <div class="d-flex">
                <div class="date-of-event">
                  <span>Apr</span>
                  <h5>12</h5>
                </div>
                <div class="events-detail ms-3">
                  <h5><router-link :to="{ name: 'social.event-detail'}">BIG 5G Event</router-link></h5>
                  <p>Lorem Ipsum is simply dummy text</p>
                  <div class="event-member">
                      <div class="iq-media-group">
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/05.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/06.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/07.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/08.jpg" alt="">
                        </a>
                        <a href="#" class="iq-media">
                        <img class="img-fluid avatar-40 rounded-circle" src="@/assets/images/user/09.jpg" alt="">
                        </a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>

  <!-- </div>
</div> -->
</template>
<script>
// import { socialvue } from '../../../config/pluginInit'
export default {
  name: 'ProfileEvent',
  mounted () {
    // socialvue.index()
  },
  data () {
    return {
      profileEvent: [
        {
          name: 'New Year Celibration',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/52.jpg'),
          month: 'Jan',
          date: '01'
        },
        {
          name: 'Birthday Celibration',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/53.jpg'),
          month: 'Jan',
          date: '24'
        },
        {
          name: 'New Year Celibration',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/54.jpg'),
          month: 'Jan',
          date: '26'
        },
        {
          name: 'Republic Day',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/55.jpg'),
          month: 'Feb',
          date: '01'
        },
        {
          name: 'Meetings & Conventions',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/56.jpg'),
          month: 'March',
          date: '01'
        },
        {
          name: 'Fun Events and Festivals ',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/57.jpg'),
          month: 'March',
          date: '11'
        },
        {
          name: 'Atlanta Retail Show',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/58.jpg'),
          month: 'Jan',
          date: '01'
        }, {
          name: 'Holi in the City',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/53.jpg'),
          month: 'March',
          date: '14'
        }, {
          name: 'Insurance Innovators',
          text: 'Lorem Ipsum is simply dummy text of the',
          backgroudimg: require('../../../assets/images/page-img/59.jpg'),
          month: 'Apr',
          date: '12'
        }
      ],
      seenUser: [
        require('../../../assets/images/user/05.jpg'),
        require('../../../assets/images/user/06.jpg'),
        require('../../../assets/images/user/07.jpg'),
        require('../../../assets/images/user/08.jpg'),
        require('../../../assets/images/user/09.jpg')
      ]
    }
  }
}
</script>
